import React from 'react'
import { SettingsPanelInterface } from '../../../interface'
import SecondaryButton from '../../common/button/SecondaryButton'
import PrimaryButton from '../../common/button/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { Switch } from '@headlessui/react'
import { classNames } from '../../utils/utils'
import useCookieStorage from '../../common/hooks/useCookieStorage'

const SettingsPanel = (props: SettingsPanelInterface) => {
  const { setCustomSetting, settingsPanel, usedCookiesType, customText } = props
  const { t } = useTranslation()
  const { handleSubmit, isGranted, handleClick, cookiesList } = useCookieStorage({ usedCookiesType, customText })

  const handleCustomSetting = () => {
    setCustomSetting(false)
  }

  return (
    <div className="flex flex-col gap-4 text-settings-panel-text bg-settings-panel-bg">
      <div className="pb-6">
        <div className="pb-4 space-y-4 border-b border-gray-200">
          <span className="font-primary text-xl font-black">
            {customText?.settingsPanel?.title ? customText.settingsPanel.title : t('settingsPanel.title')}
          </span>
          <p className="font-primary text-gray-500">
            {customText?.settingsPanel?.text ? customText.settingsPanel.text : t('settingsPanel.text')}{' '}
            {settingsPanel?.link?.link && (
              <a href={settingsPanel?.link?.link} className="font-primary text-base text-settings-panel-link underline">
                {customText?.settingsPanel?.link ? customText.settingsPanel.link : t('settingsPanel.link')}
              </a>
            )}
          </p>
        </div>
        <div className="divide-y divide-gray-200">
          {cookiesList.map((cookie, key) => (
            <Switch.Group
              key={key}
              as="div"
              className="flex flex-col md:flex-row gap-3 md:gap-6 justify-between items-center py-4"
            >
              <Switch
                checked={isGranted(cookie.name)}
                onChange={() => handleClick({ name: cookie.name, checked: !isGranted(cookie.name) })}
                className={classNames(
                  isGranted(cookie.name) ? 'bg-checkbox' : 'bg-gray-200',
                  cookie.disabled ? 'cursor-not-allowed opacity-50' : '',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-checkbox'
                )}
                disabled={cookie.disabled}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    isGranted(cookie.name) ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>

              <span className="flex flex-col flex-grow font-primary">
                <Switch.Label as="span" className="text-lg font-medium text-gray-900" passive>
                  {cookie.label}
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  {cookie.text}
                </Switch.Description>
              </span>
            </Switch.Group>
          ))}
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <SecondaryButton
          style={{
            background: settingsPanel?.secondaryButton?.backgroundColor?.gradient?.value
          }}
          className="ring-offset-settings-panel-bg font-info-panel-secondary text-settings-panel-secondary-button-text hover:text-settings-panel-secondary-button-text-hover bg-settings-panel-secondary-button-bg hover:bg-settings-panel-secondary-button-bg-hover border-settings-panel-secondary-button-border hover:border-settings-panel-secondary-button-border-hover focus:ring-settings-panel-secondary-button-ring"
          type="button"
          onClick={handleCustomSetting}
        >
          {customText?.settingsPanel?.back ? customText.settingsPanel.back : t('settingsPanel.back')}
        </SecondaryButton>
        <PrimaryButton
          style={{
            background: settingsPanel?.primaryButton?.backgroundColor?.gradient?.value
          }}
          className="ring-offset-settings-panel-bg font-info-panel-primary text-settings-panel-primary-button-text hover:text-settings-panel-primary-button-text-hover bg-settings-panel-primary-button-bg hover:bg-settings-panel-primary-button-bg-hover border-settings-panel-primary-button-border hover:border-settings-panel-primary-button-border-hover focus:ring-settings-panel-primary-button-ring"
          type="submit"
          onClick={handleSubmit}
        >
          {customText?.settingsPanel?.acceptSelected
            ? customText.settingsPanel.acceptSelected
            : t('settingsPanel.acceptSelected')}
        </PrimaryButton>
      </div>
    </div>
  )
}

export default SettingsPanel
