import React, { useState } from 'react'
import SettingsPanel from '../settings-panel/SettingsPanel'
import InfoPanel from '../info-panel/InfoPanel'
import { ConsentWrapperInterface } from '../../../interface'

export const ConsentWrapper = (props: ConsentWrapperInterface) => {
  const [customSetting, setCustomSetting] = useState(false)

  return (
    <div
      className={`overflow-y-auto fixed z-max max-h-screen shadow-2xl ${
        customSetting
          ? 'bottom-1/2 left-1/2 bg-settings-panel-bg transform -translate-x-1/2 translate-y-1/2 w-full md:max-w-6xl p-12'
          : 'bottom-0 left-0 bg-info-panel-bg w-full flex justify-center py-8 px-12'
      }`}
    >
      {customSetting ? (
        <SettingsPanel setCustomSetting={setCustomSetting} {...props} />
      ) : (
        <InfoPanel setCustomSetting={setCustomSetting} {...props} />
      )}
    </div>
  )
}

export default ConsentWrapper
