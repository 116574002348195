import React, { FormEvent } from 'react'
import { SettingsPanelInterface } from '../../../interface'
import SecondaryButton from '../../common/button/SecondaryButton'
import PrimaryButton from '../../common/button/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../utils/utils'
import useCookieStorage from '../../common/hooks/useCookieStorage'

export const SettingsPanel = (props: SettingsPanelInterface) => {
  const { setCustomSetting, usedCookiesType, customText } = props
  const { t } = useTranslation()
  const { handleSubmit, isGranted, handleClick, cookiesList } = useCookieStorage({ usedCookiesType })

  const handleCustomSetting = () => {
    setCustomSetting(false)
  }

  return (
    <div className="flex flex-col gap-4 p-10 text-settings-panel-text bg-settings-panel-bg">
      <fieldset className="border-gray-300">
        <legend className="font-primary sr-only">
          {customText?.settingsPanel?.title ? customText.settingsPanel.title : t('settingsPanel.title')}
        </legend>
        <div className="divide-y divide-gray-200">
          {cookiesList.map((cookie, key) => (
            <div key={key} className="flex relative items-start py-4">
              <div className="flex-1 min-w-0">
                <label htmlFor={cookie.name} className="font-primary text-sm font-medium text-settings-panel-text">
                  {cookie.label}
                </label>
                <p
                  id={`${cookie.name}-description`}
                  className="font-primary text-sm text-settings-panel-text text-opacity-70"
                >
                  {cookie.text}
                </p>
              </div>
              <div className="flex items-center ml-3 h-5">
                <input
                  id={cookie.name}
                  aria-describedby={`${cookie.name}-description`}
                  name={cookie.name}
                  type="checkbox"
                  defaultChecked={isGranted(cookie.name)}
                  onClick={(e: FormEvent<HTMLInputElement>) => handleClick(e.currentTarget)}
                  className={classNames(
                    cookie.disabled ? 'cursor-not-allowed opacity-50' : '',
                    'w-4 h-4 font-primary text-checkbox rounded border-gray-300 focus:ring-checkbox'
                  )}
                  disabled={cookie.disabled}
                />
              </div>
            </div>
          ))}
        </div>
      </fieldset>

      <div className="flex justify-end space-x-4">
        <SecondaryButton
          className="ring-offset-settings-panel-bg text-settings-panel-secondary-button-text hover:text-settings-panel-secondary-button-text-hover bg-settings-panel-secondary-button-bg hover:bg-settings-panel-secondary-button-bg-hover border-settings-panel-secondary-button-border hover:border-settings-panel-secondary-button-border-hover focus:ring-settings-panel-secondary-button-ring"
          type="button"
          onClick={handleCustomSetting}
        >
          {customText?.settingsPanel?.back ? customText.settingsPanel.back : t('settingsPanel.back')}
        </SecondaryButton>
        <PrimaryButton
          className="ring-offset-settings-panel-bg text-settings-panel-primary-button-text hover:text-settings-panel-primary-button-text-hover bg-settings-panel-primary-button-bg hover:bg-settings-panel-primary-button-bg-hover border-settings-panel-primary-button-border hover:border-settings-panel-primary-button-border-hover focus:ring-settings-panel-primary-button-ring"
          type="submit"
          onClick={handleSubmit}
        >
          {customText?.settingsPanel?.acceptSelected
            ? customText.settingsPanel.acceptSelected
            : t('settingsPanel.acceptSelected')}
        </PrimaryButton>
      </div>
    </div>
  )
}

export default SettingsPanel
