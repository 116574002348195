import React, { useState } from 'react'
import SettingsPanel from '../settings-panel/SettingsPanel'
import InfoPanel from '../info-panel/InfoPanel'
import { ConsentWrapperInterface } from '../../../interface'

export const ConsentWrapper = (props: ConsentWrapperInterface) => {
  const [customSetting, setCustomSetting] = useState(false)

  return (
    <div className="overflow-y-auto fixed bottom-0 left-0 z-max w-full lg:w-8/12 xl:w-6/12 max-h-screen shadow-2xl">
      {customSetting ? (
        <SettingsPanel setCustomSetting={setCustomSetting} {...props} />
      ) : (
        <InfoPanel setCustomSetting={setCustomSetting} {...props} />
      )}
    </div>
  )
}

export default ConsentWrapper
