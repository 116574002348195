import React, { Fragment } from 'react'
import ConsentWrapper from '../../components/default-layout/consent-wrapper/ConsentWrapper'
import { DefaultLayoutInterface } from '../../interface'

const DefaultLayout = (props: DefaultLayoutInterface) => {
  return (
    <Fragment>
      <ConsentWrapper {...props} />
    </Fragment>
  )
}

export default DefaultLayout
