import i18n from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export const allowedLanguages = ['cs', 'de', 'en']
export const defaultLanguage = allowedLanguages[0]

import defaultTranslations from '../public/localization/default/translations.json'
import deTranslations from '../public/localization/de/translations.json'
import enTranslations from '../public/localization/en/translations.json'


i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      cs: {
        translations: defaultTranslations,
      },
      de: {
        translations: deTranslations,
      },
      en: {
        translations: enTranslations,
      },
    },
    defaultNS: 'translations',
    ns: ['translations'],
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    debug: process.env.NODE_ENV !== 'production',
    react: {
      useSuspense: false,
    },
  })

export default i18n
