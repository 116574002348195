import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomTextInterface } from '../../../interface'

interface ConsentTriggerInterface {
  setShowConsentModal: (value: boolean) => void
  customText?: CustomTextInterface
}

export const ConsentTrigger = (props: ConsentTriggerInterface) => {
  const { setShowConsentModal, customText } = props
  const { t } = useTranslation()

  const handleClick = () => {
    setShowConsentModal(true)
  }

  return (
    <div className="p-2 w-full text-center bg-trigger-bg">
      <button type="button" onClick={handleClick} className="font-primary text-sm text-trigger-text">
        {customText?.trigger?.changeCookiesSetting
          ? customText.trigger.changeCookiesSetting
          : t('trigger.changeCookiesSetting')}
      </button>
    </div>
  )
}

export default ConsentTrigger
