import { StorageInterface } from '../interface'

declare global {
  interface Window {
    dataLayer: any
  }
}

export function gtag() {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(arguments)
  }
}

export const handleConsentUpdate = ({
  adStorage,
  analyticsStorage,
  personalizationStorage,
  securityStorage,
  functionalityStorage,
}: StorageInterface) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  gtag('consent', 'update', {
    ad_storage: adStorage,
    analytics_storage: analyticsStorage,
    functionality_storage: functionalityStorage,
    personalization_storage: personalizationStorage,
    security_storage: securityStorage,
  })
}

export const getExpirationDate = () => {
  const date = new Date()
  date.setDate(date.getDate() + 30)
  return date
}
