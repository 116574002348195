import { useEffect, useState } from 'react'
import { handleConsentUpdate } from '../../../utils/utils'
import { useTranslation } from 'react-i18next'
import { ConfigInterface, CookiesInterface } from '../../../interface'
import { useCookies } from 'react-cookie'
import {
  AD_COOKIES,
  ANALYTICS_AND_AD_COOKIES,
  ANALYTICS_COOKIES,
  PERSONALIZATION_COOKIES,
} from '../../../enums/CookiesList'
import { getCookieDomain } from '../../utils/utils'

const useCookieConsentSetup = (props: ConfigInterface) => {
  const { localization, checkbox, trigger, settingsPanel, infoPanel, fontFamily } = props
  const [isFirstUpdate, setIsFirstUpdate] = useState(true)
  const { i18n } = useTranslation()
  const [showConsentModal, setShowConsentModal] = useState(false)
  const [cookies, , removeCookie] = useCookies<string, CookiesInterface>()

  useEffect(() => {
    window.addEventListener('unload', checkDeniedCookies)
    if (cookies?.GCCookieConsent?.accepted !== true) setShowConsentModal(true)
    if (cookies?.GCCookieConsent?.storage) handleConsentUpdate(cookies.GCCookieConsent.storage)
    if (isFirstUpdate) {
      sendUpdateEvent()
      setIsFirstUpdate(false)
    }
    setLanguageHandler()
    setColorsHandler()

    return () => {
      window.removeEventListener('unload', checkDeniedCookies)
    }
  }, [cookies])

  const checkDeniedCookies = (e: Event) => {
    e.preventDefault()

    if (cookies?.GCCookieConsent?.storage?.analyticsStorage === 'denied' || !cookies.GCCookieConsent) {
      removeCookies(ANALYTICS_COOKIES)
    }
    if (cookies?.GCCookieConsent?.storage?.adStorage === 'denied' || !cookies.GCCookieConsent) {
      removeCookies(AD_COOKIES)
    }
    if (
      cookies?.GCCookieConsent?.storage?.adStorage === 'denied' ||
      cookies?.GCCookieConsent?.storage?.analyticsStorage === 'denied' ||
      !cookies.GCCookieConsent
    ) {
      removeCookies(ANALYTICS_AND_AD_COOKIES)
    }
    if (cookies?.GCCookieConsent?.storage?.personalizationStorage === 'denied' || !cookies.GCCookieConsent) {
      removeCookies(PERSONALIZATION_COOKIES)
    }
  }

  const removeCookies = (list: string[]) => {
    list.map(value => {
      if (value.includes('*')) {
        const regex = value.replace('*', '')
        Object.keys(cookies).map((cookie: string) => {
          if (cookie.includes(regex)) removeCookieHandler(value)
        })
      } else {
        removeCookieHandler(value)
      }
    })
  }

  const removeCookieHandler = (value: string) => {
    return removeCookie(value, { path: '/', domain: getCookieDomain() })
  }

  const sendUpdateEvent = () => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event: 'gc_cookie_consent_update' })
    }
  }

  const setLanguageHandler = () => {
    i18n.changeLanguage(localization)
  }

  const setColor = (property: string, value: string) => {
    return document.documentElement.style.setProperty(property, value)
  }

  const setColorsHandler = () => {
    fontFamilies.map(value => {
      setColor(value[0], value[1])
    })
    checkboxColors.map(value => {
      setColor(value[0], value[1])
    })
    triggerColors.map(value => {
      setColor(value[0], value[1])
    })
    infoPanelColors.map(value => {
      setColor(value[0], value[1])
    })
    settingsPanelColors.map(value => {
      setColor(value[0], value[1])
    })
  }

  const checkboxColors = [['--color-checkbox', checkbox || '22, 163 ,74']]
  const fontFamilies = [
    ['--panel-font-family', fontFamily ? fontFamily : 'Montserrat'],
    ['--color-info-panel-primary-button-font-family', infoPanel?.primaryButton?.fontFamily || 'Montserrat'],
    ['--color-info-panel-secondary-button-font-family', infoPanel?.secondaryButton?.fontFamily || 'Montserrat'],
    ['--color-settings-panel-primary-button-font-family', settingsPanel?.primaryButton?.fontFamily || 'Montserrat'],
    ['--color-settings-panel-secondary-button-font-family', settingsPanel?.secondaryButton?.fontFamily || 'Montserrat'],
  ]

  const triggerColors = [
    ['--color-trigger-text', trigger?.textColor || '156, 163, 175'],
    ['--color-trigger-bg', trigger?.backgroundColor || '243, 244 ,246'],
  ]

  const infoPanelColors = [
    ['--color-info-panel-bg', infoPanel?.backgroundColor || '243, 244, 246'],
    ['--color-info-panel-text', infoPanel?.textColor || '17, 24 ,39'],
    ['--color-info-panel-link', infoPanel?.link?.textColor || '22, 163, 74'],
    ['--color-info-panel-primary-button-text', infoPanel?.primaryButton?.textColor?.default || '255, 255, 255'],
    ['--color-info-panel-primary-button-text-hover', infoPanel?.primaryButton?.textColor?.hover || '255, 255, 255'],
    ['--color-info-panel-primary-button-bg', infoPanel?.primaryButton?.backgroundColor?.default || '22 ,163, 74'],
    ['--color-info-panel-primary-button-bg-hover', infoPanel?.primaryButton?.backgroundColor?.hover || '21 ,128, 61'],
    ['--color-info-panel-primary-button-border', infoPanel?.primaryButton?.borderColor?.default || '22, 163 ,74'],
    ['--color-info-panel-primary-button-border-hover', infoPanel?.primaryButton?.borderColor?.hover || '21, 128 ,61'],
    ['--color-info-panel-primary-button-ring', infoPanel?.primaryButton?.ringColor || '34 ,197 ,94'],

    ['--color-info-panel-secondary-button-text', infoPanel?.secondaryButton?.textColor?.default || '0 ,0 ,0'],
    ['--color-info-panel-secondary-button-text-hover', infoPanel?.secondaryButton?.textColor?.hover || '0 ,0 ,0'],
    ['--color-info-panel-secondary-button-bg', infoPanel?.secondaryButton?.backgroundColor?.default || '229, 231 ,235'],
    [
      '--color-info-panel-secondary-button-bg-hover',
      infoPanel?.secondaryButton?.backgroundColor?.hover || '209, 213 ,219',
    ],
    ['--color-info-panel-secondary-button-border', infoPanel?.secondaryButton?.borderColor?.default || '229, 231 ,235'],
    [
      '--color-info-panel-secondary-button-border-hover',
      infoPanel?.secondaryButton?.borderColor?.hover || '209 ,213 ,219',
    ],
    ['--color-info-panel-secondary-button-ring', infoPanel?.secondaryButton?.ringColor || '107, 114 ,128'],
  ]

  const settingsPanelColors = [
    ['--color-settings-panel-bg', settingsPanel?.backgroundColor || '243, 244, 246'],
    ['--color-settings-panel-text', settingsPanel?.textColor || '17, 24, 39'],
    ['--color-settings-panel-link', settingsPanel?.link?.textColor || '22, 163, 74'],
    ['--color-settings-panel-primary-button-text', settingsPanel?.primaryButton?.textColor?.default || '255, 255, 255'],
    [
      '--color-settings-panel-primary-button-text-hover',
      settingsPanel?.primaryButton?.textColor?.hover || '255, 255, 255',
    ],
    [
      '--color-settings-panel-primary-button-bg',
      settingsPanel?.primaryButton?.backgroundColor?.default || '22 ,163, 74',
    ],
    [
      '--color-settings-panel-primary-button-bg-hover',
      settingsPanel?.primaryButton?.backgroundColor?.hover || '21 ,128, 61',
    ],
    [
      '--color-settings-panel-primary-button-border',
      settingsPanel?.primaryButton?.borderColor?.default || '22, 163 ,74',
    ],
    [
      '--color-settings-panel-primary-button-border-hover',
      settingsPanel?.primaryButton?.borderColor?.hover || '21, 128 ,61',
    ],
    ['--color-settings-panel-primary-button-ring', settingsPanel?.primaryButton?.ringColor || '34 ,197 ,94'],
    ['--color-settings-panel-secondary-button-text', settingsPanel?.secondaryButton?.textColor?.default || '0 ,0 ,0'],
    [
      '--color-settings-panel-secondary-button-text-hover',
      settingsPanel?.secondaryButton?.textColor?.hover || '0 ,0 ,0',
    ],
    [
      '--color-settings-panel-secondary-button-bg',
      settingsPanel?.secondaryButton?.backgroundColor?.default || '229, 231 ,235',
    ],
    [
      '--color-settings-panel-secondary-button-bg-hover',
      settingsPanel?.secondaryButton?.backgroundColor?.hover || '209, 213 ,219',
    ],
    [
      '--color-settings-panel-secondary-button-border',
      settingsPanel?.secondaryButton?.borderColor?.default || '229, 231 ,235',
    ],
    [
      '--color-settings-panel-secondary-button-border-hover',
      settingsPanel?.secondaryButton?.borderColor?.hover || '209 ,213 ,219',
    ],
    ['--color-settings-panel-secondary-button-ring', settingsPanel?.secondaryButton?.ringColor || '107, 114 ,128'],
  ]

  return { showConsentModal, setShowConsentModal }
}

export default useCookieConsentSetup
