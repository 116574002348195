import { useCookies } from 'react-cookie'
import { CookiesInterface, GCCookieConsentInterface, StorageInterface } from '../../../interface'
import { useEffect, useState } from 'react'
import { getExpirationDate } from '../../../utils/utils'
import { useTranslation } from 'react-i18next'
import { CookiesList, UseCookieStorageInterface } from '../../../interface/components/hooks/useCookieStorageInterface'
import { getCookieDomain } from '../../utils/utils'

const useCookieStorage = (props: UseCookieStorageInterface) => {
  const { usedCookiesType, customText } = props
  const [cookies, setCookie] = useCookies<string, CookiesInterface>(['GCCookieConsent'])
  const [storage, setStorage] = useState<StorageInterface>({
    adStorage: cookies?.GCCookieConsent?.storage?.adStorage || 'denied',
    analyticsStorage: cookies?.GCCookieConsent?.storage?.analyticsStorage || 'denied',
    personalizationStorage: cookies?.GCCookieConsent?.storage?.personalizationStorage || 'denied',
    functionalityStorage: 'granted',
    securityStorage: 'granted',
  })
  const [cookiesList, setCookiesList] = useState<CookiesList[]>([])
  const [allowAllStorage, setAllowAllStorage] = useState<StorageInterface>({})
  const { t } = useTranslation()

  useEffect(() => {
    getWantedConsent()
  }, [])

  const getWantedConsent = () => {
    const tmpCookieList: CookiesList[] = []
    const tmpAllowAllStorage: StorageInterface = {
      adStorage: 'denied',
      analyticsStorage: 'denied',
      functionalityStorage: 'denied',
      personalizationStorage: 'denied',
      securityStorage: 'denied',
    }

    usedCookiesType?.map(cookie => {
      switch (cookie) {
        case 'ad': {
          tmpCookieList.push({
            label: customText?.adCookies?.label ? customText.adCookies.label : t('adCookies.label'),
            name: 'adStorage',
            text: customText?.adCookies?.text ? customText.adCookies.text : t('adCookies.text'),
          })

          tmpAllowAllStorage.adStorage = 'granted'
          break
        }
        case 'analytics': {
          tmpCookieList.push({
            label: customText?.analyticsCookies?.label
              ? customText.analyticsCookies.label
              : t('analyticsCookies.label'),
            name: 'analyticsStorage',
            text: customText?.analyticsCookies?.text ? customText.analyticsCookies.text : t('analyticsCookies.text'),
          })

          tmpAllowAllStorage.analyticsStorage = 'granted'
          break
        }
        case 'personalization': {
          tmpCookieList.push({
            label: customText?.personalizationCookies?.label
              ? customText.personalizationCookies.label
              : t('personalizationCookies.label'),
            name: 'personalizationStorage',
            text: customText?.personalizationCookies?.text
              ? customText.personalizationCookies.text
              : t('personalizationCookies.text'),
          })

          tmpAllowAllStorage.personalizationStorage = 'granted'
          break
        }
      }
    })

    tmpCookieList.push({
      label: customText?.necessaryCookies?.label ? customText.necessaryCookies.label : t('necessaryCookies.label'),
      name: 'necessary',
      text: customText?.necessaryCookies?.text ? customText.necessaryCookies.text : t('necessaryCookies.text'),
      disabled: true,
    })

    tmpAllowAllStorage.securityStorage = 'granted'
    tmpAllowAllStorage.functionalityStorage = 'granted'

    setCookiesList(tmpCookieList)
    setAllowAllStorage(tmpAllowAllStorage)
  }

  const handleSubmit = () => {
    const cookie: GCCookieConsentInterface = {
      accepted: true,
      storage,
    }
    setCookieHandler(cookie)
  }

  const isGranted = (cookie: string) => {
    if (cookie === 'necessary') {
      return true
    } else {
      return storage?.[cookie] === 'granted'
    }
  }

  const handleClick = (value: { name: string; checked: boolean }) => {
    const tmpStorage = { ...storage }
    tmpStorage[value.name] = value.checked ? 'granted' : 'denied'
    setStorage(tmpStorage)
  }

  const handleAllowAll = () => {
    const cookie: GCCookieConsentInterface = {
      accepted: true,
      storage: allowAllStorage,
    }
    setCookieHandler(cookie)
  }

  const setCookieHandler = (cookie: GCCookieConsentInterface) => {
    setCookie('GCCookieConsent', cookie, {
      path: '/',
      sameSite: 'strict',
      expires: getExpirationDate(),
      domain: getCookieDomain(),
      secure: process.env.NODE_ENV === 'production',
    })

    // It is needed to reload the page to remove previously fired tags
    setTimeout(() => {
      return location.reload(true)
    }, 50)
  }

  return { handleSubmit, isGranted, handleClick, cookiesList, handleAllowAll }
}

export default useCookieStorage
