import { ConfigInterface } from '../interface'
import React from 'react'
import ConsentTrigger from '../components/default-layout/consent-trigger/ConsentTrigger'
import DefaultLayout from '../layouts/default/DefaultLayout'
import ModalLayout from '../layouts/modal/ModalLayout'
import useCookieConsentSetup from '../components/common/hooks/useCookieConsentSetup'

const CookieConsent = (props: ConfigInterface) => {
  const { layout } = props
  const { showConsentModal, setShowConsentModal } = useCookieConsentSetup(props)

  const getLayout = () => {
    switch (layout) {
      case 'modal': {
        return <ModalLayout {...props} />
      }
      case 'default':
      default: {
        return <DefaultLayout {...props} />
      }
    }
  }

  return (
    <div id="gc-cookie-consent">
      {showConsentModal && getLayout()}

      <ConsentTrigger setShowConsentModal={setShowConsentModal} {...props} />
    </div>
  )
}

export default CookieConsent
