import React, { Fragment } from 'react'
import { ModalLayoutInterface } from '../../interface'
import ConsentWrapper from '../../components/modal-layout/consent-wrapper/ConsentWrapper'

const ModalLayout = (props: ModalLayoutInterface) => {
  return (
    <Fragment>
      <ConsentWrapper {...props} />
    </Fragment>
  )
}

export default ModalLayout
