import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ConfigInterface, initInterface } from './interface'
import './index.css'
import './i18n'
import { CookiesProvider } from 'react-cookie'
import CookieConsent from './views/CookieConsent'

export const init: initInterface = (config, selector) => {
  return ReactDOM.render(
    <StrictMode>
      <GCCookieConsent {...config} />
    </StrictMode>,
    selector
  )
}

export const GCCookieConsent = (props: ConfigInterface) => {
  return (
    <CookiesProvider>
      <CookieConsent {...props} />
    </CookiesProvider>
  )
}
