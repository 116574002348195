import React from 'react'
import { InfoPanelInterface } from '../../../interface'
import PrimaryButton from '../../common/button/PrimaryButton'
import SecondaryButton from '../../common/button/SecondaryButton'
import { useTranslation } from 'react-i18next'
import useCookieStorage from '../../common/hooks/useCookieStorage'

export const InfoPanel = (props: InfoPanelInterface) => {
  const { setCustomSetting, infoPanel, usedCookiesType, customText } = props
  const { handleAllowAll } = useCookieStorage({ usedCookiesType })
  const { t } = useTranslation()

  const handleCustomSetting = () => {
    setCustomSetting(true)
  }

  return (
    <div className="flex flex-col md:flex-row gap-16 max-w-7xl">
      <div className="space-y-4">
        <span className="block font-primary text-xl font-bold text-info-panel-text">
          {customText?.infoPanel?.title ? customText.infoPanel.title : t('infoPanel.title')}
        </span>
        <div>
          <p className="font-primary text-base text-info-panel-text text-opacity-80">
            {customText?.infoPanel?.text ? customText.infoPanel.text : t('infoPanel.text')}{' '}
            {infoPanel?.link?.link && (
              <a href={infoPanel?.link?.link} className="font-primary text-base text-info-panel-link underline">
                {customText?.settingsPanel?.link ? customText.settingsPanel.link : t('settingsPanel.link')}
              </a>
            )}
          </p>
        </div>
      </div>
      <div className="flex flex-shrink-0 items-center">
        <div className="space-x-4">
          <SecondaryButton
            style={{
              background: infoPanel?.secondaryButton?.backgroundColor?.gradient?.value
            }}
            className="ring-offset-info-panel-bg font-info-panel-secondary text-info-panel-secondary-button-text hover:text-info-panel-secondary-button-text-hover bg-info-panel-secondary-button-bg hover:bg-info-panel-secondary-button-bg-hover border-info-panel-secondary-button-border hover:border-info-panel-secondary-button-border-hover focus:ring-info-panel-secondary-button-ring"
            type="button"
            onClick={handleCustomSetting}
          >
            {customText?.infoPanel?.manageCookies ? customText.infoPanel.manageCookies : t('infoPanel.manageCookies')}
          </SecondaryButton>

          <PrimaryButton
            style={{
              background: infoPanel?.primaryButton?.backgroundColor?.gradient?.value
            }}
            className="ring-offset-info-panel-bg font-info-panel-primary text-info-panel-primary-button-text hover:text-info-panel-primary-button-text-hover bg-info-panel-primary-button-bg hover:bg-info-panel-primary-button-bg-hover border-info-panel-primary-button-border hover:border-info-panel-primary-button-border-hover focus:ring-info-panel-primary-button-ring"
            type="button"
            onClick={handleAllowAll}
          >
            {customText?.infoPanel?.acceptAll ? customText.infoPanel.acceptAll : t('infoPanel.acceptAll')}
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}

export default InfoPanel
