import React from 'react'
import { ButtonInterface } from '../../../interface'

export const Button = (props: ButtonInterface) => {
  const { className, ...rest } = props

  return (
    <button
      {...rest}
      type="button"
      className={`inline-flex items-center py-2 px-4 text-sm font-medium rounded-md border-2 focus:ring-2 focus:ring-offset-2 shadow-sm focus:outline-none ${
        className ? className : 'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 '
      }`}
    />
  )
}

export default Button
